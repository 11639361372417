@import './../../../../styles/mixins.scss';


.modal-content {
  .actions {
    .clear-btn {
      margin-right: calculateRem(20);
    }
  
    .add-btn {
      @include width(85);
      @include margin(0, 15, 0, 0);
    }
  }
  .combination-container {
    @include padding(0, 24, 0, 0);
  }
  .combination-container .add-data-container {
    box-shadow: none;
    padding: 0px;
  }

  .combination-container .add-data-container .filter-group {
    position: relative;
  }

  .combination-container .error {
    bottom: -14px;
  }
  .edit-combination-container {
    background-color: $white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    @media (min-width: 576px) and (max-width: 1030px) {
      width: 740px;
    }
    @media (min-width: 1030px) and (max-width: 1440px){
      width: 810px;
    }
    @media (min-width: 1440px) and (max-width: 1869px){
      width: 920px;
    }
    @media (min-width: 1869px){
      width: 1150px;
    }
  }
}

