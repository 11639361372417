@import './../../styles/mixins.scss';
@import './../../styles/fonts.scss';
@import './../../styles/utils.scss';

.suggested-salaries-list {
  margin-bottom: -1.625rem;
  .filter-div {
    @include flexbox();
  }

  .mt-n18 {
    margin-top: calculateRem(-18);
  }

  .table-div {
    color: $light-black;
    @include tablet {
      min-height: calc(100vh - 18.2rem);
    }

    table {
      width: 100%;

      td, th {
        @include padding(13, 9, 12, 9);
        &:nth-child(1) {
          width: 13.5%;
        }

        &:nth-child(2) {
          width: 9%;
        }

        &:nth-child(3) {
          width: 12%;
        }

        &:nth-child(4) {
          width: 12.5%;
        }

        &:nth-child(5) {
          width: 15.5%;
        }

        &:nth-child(6), &:nth-child(7), &:nth-child(8){
          width: 9.5%;
        }

        &:nth-child(9) {
          padding-left: 0.5rem;
          padding-right: 0.5rem;
          width: 9%;
          text-align: end;
        }

        @include fullHd {
          &:nth-child(9) {
            width: 7.5%;
          }
        }
      }

      th {
        padding-top: 0;
        padding-bottom: 0;
      }

      td {
        &:nth-child(6), &:nth-child(7), &:nth-child(8){
          width: 9.5%;
          @include inter-medium();
          border: 1px solid #e6e6e6;
          @include fontSize(14);
          @include desktop {
            @include fontSize(16);
          }
          @include fullHd {
            @include fontSize(18);
          }
        }
      }

      tbody {
        tr {
          border: 1px solid rgba(151, 151, 151, 0.24);
          @include inter-regular();
          @include fontSize(12);
          @include desktop {
            @include fontSize(14);
          }
          @include fullHd {
            @include fontSize(16);
          }
          .edit-icon, .clear-btn {
            visibility: hidden;
          }

          &:nth-child(odd) {
            background-color: rgba(236, 236, 236, 0.24);
          }

          &:nth-child(even) {
            background-color: rgba(210, 236, 247, 0.24);
          }

          .override {
            color: $light-black;
            opacity: 0.75;
            text-decoration: line-through $error-red;
            margin-right: 0.5rem;
          }

          .clear-btn {
            background-color: $red-1;
            color: $white;
            @include fontSize(10);
            @include padding(1, 10);
            margin-right: 0.5rem;
          }
          .clear-btn[disabled] {
            cursor: not-allowed;      
            opacity: 0.3;             
          }
          }

        tr:hover .edit-icon {
          @extend .cursor-pointer;
          @include width(22);
          @include height(24);
        }
        tr:hover .disabled-edit {
          cursor: auto;
        }

        tr:hover .clear-btn, tr:hover .edit-icon {
          visibility: visible;
        }
      }
      .header-text {
        @include fontSize(13);
        color: $light-black;
        @include inter-semibold();
        vertical-align: bottom
      }
      .subheader-text {
        @include fontSize(12);
        color: $light-black;
        @include inter-medium();
        vertical-align: bottom;
        height: 30px;
      }
      .min {
        display: flex;
        align-items: flex-end;
      }
    }
  }

  .mb-26 {
    margin-bottom: calculateRem(26);
  }

  .actions {
    position: sticky;
    bottom: 0;
    width: calc(100% + 5.0625rem);
    background-color: $white;
    box-shadow: 0px 2px 6px $light-black;
    @include padding(26, 51, 26, 30);
    @include margin(0, 0, 0, -30);
    @include flexbox();
    @include justify-content(flex-end);

    .add-btn {
      width: auto;
      background-color: $dark-blue;
      white-space: nowrap;
    }
  }
  .disabled-edit{
    opacity: 0.3;
    cursor: auto;
  }
}