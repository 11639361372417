@import '../../../styles/fonts.scss';
@import '../../../styles/variables.scss';
@import '../../../styles/mixins.scss';

.location-container {
    .location-item {
        @include flexbox();
        align-items: center;
        flex-direction: row;
        @include inter-regular();
        .location-name {
            width: calculateRem(275);
            @include fontSize(16);
            @include inter-medium();
            color: $light-black;
        }
        .location-type {
            @include margin(0, 13, 0, 0);
            @include fontSize(12);
            @include inter-regular();
            color: $light-black;
            align-self: center
        }
        .fixed {
            @include margin(0, 15, 0, 0);
        }
        .input-container {
            position: relative;
        }
        .input-text {
            @include padding(8);
            @include fontSize(13);
            @include inter-regular();
            color: $light-black;
            border-radius: 2px;
            border: solid 1px rgba(47, 47, 47, 0.57);
            width: calculateRem(129);
        }
        .percent-text {
            width: calculateRem(93);
        }
        input::placeholder {
            text-align: right;
        }

        .error {
            @include margin(2, 0, 0, 0);
            @include fontSize(10);
            @include inter-regular();
            color: $error-red;
            position: absolute;
            min-width: calculateRem(400)
        }

        .invalid{
            border:1px solid #ff0000
            }
    }

    .border-line {
      @include height(1);
      @include margin(20, 0, 11, 0);
      background: rgba(47, 47, 47, 0.5);
      opacity: 0.6;
    }

    .dropup, .dropend, .dropdown, .dropstart, .dropup-center, .dropdown-center {
        .filter-types {
            width: calculateRem(200);
            @include fontSize(13);
            @include inter-regular();
            color: $light-black;
        }
    }
    
    .dropdown>a::after {
        color: $light-black;
    }

    .dropdown>a {
        @include margin(0, 44, 0, 0);
        @include fontSize(12);
        border-radius: 2px;
        border: solid 1px rgba(47, 47, 47, 0.5);
        @include padding(8);
        display: flex;
        flex-direction: row;
        align-items: center;
    }
}
