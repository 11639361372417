@import './../../styles/mixins.scss';
@import './../../styles/fonts.scss';
@import './../../styles/variables.scss';

.config-filter-item {
  @include flexbox();
  @include justify-content(center);
  @include flex-direction(row);
  max-width: max-content;
  border-radius: 2px;
  border: solid 1px $dropdown-border;


  .filter-select {
    display: flex;
    flex-direction: row;
    @include fontSize(14);
    color: $light-black;
  }

  .drop-down {
    width: calculateRem(17);
    height: calculateRem(7);
  }

  .dropdown-item {
    @include inter-regular();
    @include fontSize(14);
    color: $light-black;
  }

  .dropdown-menu {
    max-height: 400px;
    overflow-y: auto;
  }

  .nav-link {
    @include padding(2, 16, 2, 8);
    @include inter-regular();
    @include fontSize(13);
    min-width: calculateRem(120);
    color: $light-black;
  }

  :focus-visible {
    outline: none;
  }

  .dropdown-toggle::after {
    position: absolute;
    right: 4px;
    top: 40%;
  }

  .dropdown-placeholder {
    display: block;
    width: 6.5875rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.stream-filter {
  .dropdown-placeholder {
    width: 10.5875rem;
  }
} 