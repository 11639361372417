
@import './../../styles/mixins.scss';
@import './../../styles/variables.scss';

.loader-overlay {
  display: block;
  width: 100%;
  height: 100%;
  @include fixed(0, auto, auto, 0);
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999999;
}

.loader {
  @include absolute(calc(50% - 1.5rem), auto, auto, calc(50% - 1.5rem));
  @include width(48);
  @include height(48);
  @include borderRadius(25);
  display: inline-block;
  border-top: 0.25rem solid $white;
  border-right: 0.25rem solid transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}