@import "./utils";
@import "./variables.scss";
@import "./modal.scss";
@import './fonts.scss';
@import './mixins.scss';

html {
  height: 100%;

  @include mobile {
    font-size: 14px;
  }
  @include tablet {
    font-size: 11px;
  }
  @include desktop {
    font-size: 13px;
  }
  @include hd {
    font-size: 14px;
  }
  @include hdPlus {
    font-size: 16px;
  }
  @include fullHd {
    font-size: 18px;
  }
  @include quadHd {
    font-size: 20px;
  }
  @include uHd {
    font-size: 22px;
  }
}

body {
  padding: 0;
  margin: 0;
  @include inter();
}

#root {
  min-height: 100%;
  position: relative;
}

.val-error {
  border-color: $red !important;
}

.display-flex {
  display: flex;
}

input:disabled {
  background-color: $input-disabled-bg-color;
}

input {
  color: $light-black;
  border: solid 1px $input-border-color;
  outline: none;
  padding: 2px 7px;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}

.btn {
  padding: 5px 15px;
  border-radius: 3px;
  border: none;
}

.btn-primary {
  color: $white;
  background-color: $btn-primary-bg-color;
}

.tooltip  {
  min-width: 260px;
  --bs-tooltip-bg: #636363;
  --bs-tooltip-arrow-width: 1.5rem;

  .tooltip-inner {
    max-width: none;
  }
}

.btn-secondary {
  color: $light-black;
  background-color: $btn-secondary-bg-color;
}

.listing-table {
  width: 100%;

  thead tr, tbody tr {
    line-height: 40px;
    border-bottom: 2px solid $table-border;
  }

  tbody tr td {
    font-size: 12px;
  }
}

.app-div {
  @include inter();
}

.title-blue-font {
  color: $title-blue;
}

.page-heading {
  @include inter-medium();
  @include fontSize(18);
  color: $light-black;
}

.country-name {
  @include inter-regular();
  @include fontSize(13);
  color: $light-black;
}

.add-offset-form {
  @include inter-regular();
  @include fontSize(14);
  @include padding(17, 29, 17, 26);
  @include borderRadius(4);
  @include flexbox();
  @include align-items(center);
  @include justify-content(space-around);
  @include relative();
  margin-top: calculateRem(30);
  color: rgba(47, 47, 47, 0.6);
  box-shadow: 0 0 3px 0 $main-blue;

  .duplicate-error {
    width: 100%;
    text-align: center;
    color: $error-red;
    @include fontSize(10);
    @include absolute(auto, auto, 0, 0);
    white-space: nowrap;
  }
}

.add-dropdown {
  border: 1px solid rgba(47, 47, 47, 0.5);
  margin-left: calculateRem(14);
  @include inter-regular();
  @include fontSize(13);
  @include width(160);

  .dropdown {
    .nav-link {
      @include padding(5.6, 0, 5.6, 8);
    }
  }

  .dropdown-placeholder {
    width: 7.5875rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: $light-black;
    opacity: 0.6;
    line-height: normal;
  }

  .dropdown-toggle::after {
    position: absolute;
    right: 4px;
    top: 40%;
    color: $light-black;
  }

  .toggle-btn {
    @include absolute(8px, -25px, auto, auto);
  }

  .dropdown-menu {
    top: 1.75rem !important;
    left: -2px !important;
    transform: none !important;
    max-height: 20rem;
    overflow-y: auto;
    overflow-x: hidden
  }
}

.rating-input-div {
  @include relative();
  @include flexbox();
  @include align-items(center);

  &::after {
    content: "%";
    @include absolute(auto, 4%, auto, auto);
    height: 100%;
    display: flex;
    align-items: center;
  }

  &input::placeholder {
    color: rgba(47, 47, 47, 0.6);
  }

  .input-error {
    color: $error-red;
    @include fontSize(10);
    @include absolute(calculateRem(28), 0px, auto, auto);
  }
}

.rating-input {
  @include fontSize(14);
  @include width(76);
  @include borderRadius(2);
  padding-top: calculateRem(4);
  padding-right: calculateRem(18);
  margin-left: calculateRem(4);
  border: solid 1px rgba(47, 47, 47, 0.57);
}

.actions {
  @include align-items(center);

  .clear-btn, .add-btn {
    @include width(74);
    @include height(27);
    @include borderRadius(3);
    @include fontSize(14);
    @include inter-semibold();
    line-height: calculateRem(14);
    margin-left: calculateRem(56);
    background-color: $light-gray;
    color: $light-black;

    &:hover {
      color: $light-black;
      background-color: $light-gray;
    }
  }

  .add-btn {
    @include width(63);
    color: $white;
    background-color: $main-blue;
    margin-left: calculateRem(20);

    &:hover {
      color: $white;
      background-color: $main-blue;
    }
  }
}

.btn:hover {
  border-color: transparent;
}

.btn:focus, .btn:active {
  box-shadow: none !important;
}

.dropdown-item {
  color: $light-black;
  @include fontSize(14);
  &:active {
    background-color: $gray-1;
    color: $light-black;
  }
}

.modal {
  z-index: 99990;
}

.modal-content {
  color: $light-black;
  @include borderRadius(4);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5), 0 1px 26px 0 rgba(0, 0, 0, 0.41);

  .modal-header {
    @include padding(22, 23, 0, 25);

    .modal-title {
      @include padding(0);
      @include inter-medium();
      @include fontSize(20);
    }

    .close-btn {
      @include width(16);
      @include height(16);
      @extend .cursor-pointer;
    }
  }

  .modal-body {
    @include padding(20, 23, 5, 25);
    @include fontSize(14);
    @include inter-regular();
  }
}

.modal-backdrop.show {
  opacity: 0.3;
}

.nav-link, .dropdown-item:focus {
  outline: none;
}

.fs-14 {
  @include fontSize(14, true);
}

.version-label {
  @include flexbox();
  @include height(18);
  @include align-items(center);
  @include padding(0, 3);
  margin-left: calculateRem(10);
  border-radius: calculateRem(3);
  background: $green;

  img {
    @include width(12);
    @include height(12);
    margin-right: calculateRem(2);
  }

  &.previous-label {
    background-color: $light-green;
  }

  &.draft-label {
    background-color: $dark-gray;
    @include padding(0, 8);
  }
}

.published-text {
  @include inter-medium();
  @include fontSize(10);
  color: $white;
  letter-spacing: 0.03px;
  white-space: nowrap;
}

.text-semibold {
  @include inter-semibold();
}