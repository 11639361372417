@import './../../styles/fonts.scss';
@import './../../styles/mixins.scss';
@import "react-toastify/dist/ReactToastify.css";

.Toastify {
  > div {
    width: auto;
    min-width: calculateRem(312);
    z-index: 99991;

    > div {
      @include fontSize(14);
      @include inter-medium();

      > div.Toastify__toast-body {
        @include padding(12);
        line-height: calculateRem(20);
      }
    }
  }
}