@import '../../../styles/fonts.scss';
@import '../../../styles/variables.scss';
@import '../../../styles/mixins.scss';

.combination-container {
    .add-data-container {
        @include flexbox();
        @include flex-direction(row);
        justify-content: space-between;
        border-radius: calculateRem(3);
        box-shadow: 0 0 3px 0 $btn-primary-bg-color;
        @include padding(17, 20, 17, 39);

        .filter-group {
            @include flexbox();
            @include flex-direction(row);
            align-items: center;
        }
        .filter-name {
            position: relative;
            margin-right: calculateRem(21);
            @include inter-regular();
            @include fontSize(14);
            color: $light-black;
            opacity: 0.65;
        }
    }

    .list-container {
      margin-top: calculateRem(19);
      .combination-item-container {
        .combination-item {
          @include flexbox();
          @include flex-direction(row);
          justify-content: space-between;
          @include padding(16, 0, 11, 0);

          .item {
              @include fontSize(14);
              @include inter-regular();
              color: $light-black;
              width: calculateRem(193);
          }
          .location {
            @include inter-medium();
            @include fontSize(16);
          }
          .offset {
            width: calculateRem(40);
          }
          .rating {
            width: calculateRem(120);
          }
          .actions {
            display: flex;
            flex-direction: row;
            .edit {
              margin-right: calculateRem(17);
              @include padding(0, 4, 0, 4);
              visibility: hidden;
              cursor: pointer;

              .icon {
                width: calculateRem(22);
                height: calculateRem(22);
              }
            }
          }
          .view-only {
            width: calculateRem(80);
          }
        }
     }

      .combination-item-container:hover {
        .combination-item {
          .actions {
            .edit {
            visibility: visible;
            }
          }
        }
      }
   }

   .error {
    @include fontSize(10);
    @include inter-regular();
    color: $error-red;
    position: absolute;
    min-width: calculateRem(200);
    bottom: 2px;
  }

  .edit-duplicate {
    bottom: -16px;
  }

  .mt-35 {
    margin-top: calculateRem(35);
  }

  .offset-error {
    left: 4px;
    bottom: -18px;
  }

  .invalid {
    border:1px solid #ff0000
  }

    .action-btn-group {
        @include flexbox();
        @include flex-direction(row);
        justify-content: flex-end;
      
        .action-btn {
          border-radius: 3px;
          @include padding(5, 16, 5, 16);
          @include margin(0, 20, 0, 0);
          cursor: pointer;
          @include fontSize(14);
          @include inter-semibold();
          color: $light-black;
        }
      
        .clear {
          background: #e9e9e9;
          color:  $light-black;
        }
      
      .add {
          background: #00aaf3;
          color:  $white;
        }

      .action-btn.is-disabled {
        pointer-events: none;
        opacity: 0.7;
      }
    }

    input {
      @include padding(1, 5, 1, 5);
      @include inter-regular();
      @include fontSize(14);
      color: $light-black;
      width: calculateRem(62);
    }

    .border-line {
      @include height(1);
      background: rgba(47, 47, 47, 0.5);
      opacity: 0.6;
    }

    .add-offset-form .rating-input-div {
      &::after {
        content: "";
      }
   }

   .add-offset-form .rating-input {
    padding-right: 0px;
  }
}


.Toastify > div > div > div.Toastify__toast-body {
  white-space: pre-line;
} 