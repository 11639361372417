@import '../../../../styles/fonts.scss';
@import '../../../../styles/variables.scss';
@import '../../../../styles/mixins.scss';

.config-card {
    background: $main-blue;
    width: calc(25% - 22px);
    margin-right: 22px;
    margin-bottom: 27px;
    @include borderRadius(6, 6, 6, 6);
    box-shadow: 0 0 17px 0 rgba(0, 0, 0, 0.1);

    .card-header {
      position: relative;
      @include padding(91, 10, 0, 0);
      @include flexbox;
      @include height(198);
      @include margin(0, 5, 0, 17);
      color: $white;
      justify-content: space-between;

      .card-name {
        @include fontSize(20);
        @include inter-medium();

        .card-number {
          @include fontSize(30);
          @include inter-light();
          opacity: 0.7;
        } 

        .split-name {
          white-space: pre-line
        }
      }
      .right-arrow {
        height: 100%;
        width: auto;
      }
    }

    .config-card-footer {
      height: calculateRem(62);
      @include padding(0, 20, 0, 20);
      align-items: center;
      @include flexbox;
      background: $white;
      justify-content: space-between;

      .view-btn {
        @include fontSize(14);
        @include inter-regular();
        color: $white;
        background: $title-blue;
        @include padding(5, 14, 5, 14);
        border-radius: calculateRem(3);
        @extend .cursor-pointer;
      }
    }

    .right-align {
      justify-content: right;
    }
    
    .graph-img {
      width: calculateRem(24);
      height: calculateRem(24);
    }

    .hide-button {
      display: none;
    }
}
