@import '../../../styles/mixins.scss';
@import '../../../styles/fonts.scss';

.compute-container {
  min-height: calc(100vh - 12.5rem);

  .compute-content {
    @include flexbox();
    @include align-items(center);
    @include justify-content(center);
    @include flex-direction(column);
    min-height: 20vh;

    .text {
      @include inter-regular();
      @include fontSize(15);
      color: $light-black;
    }

    .actions {
      margin-top: 1rem;
      .add-btn {
        @include width(100);
        @include height(40);
      }
    }
  }
}