@import './../../../styles/mixins.scss';

.clone-version-modal {
    .modal-dialog {
        @include width(523);
    }
}

.clone-modal-body {
    padding: 10px 0;
    &>div {
        label {
            font-size: 14px;
        }

        input {
            min-width: 100%;
        }
    }

    .input-field {
        @include relative();
        .input-error {
            color: $error-red;
            @include fontSize(10);
            @include absolute(calculateRem(28), auto, auto, 0px);
        }
    }
}

.clone-actions {
    .add-btn {
        @include width(63, true);
    }
}