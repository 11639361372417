@import './../../styles/mixins.scss';
@import './../../styles/fonts.scss';
@import './../../styles/variables.scss';

.action-btn-container {
  @include flexbox();
  @include flex-direction(row);
  justify-content: flex-end;
  @include margin(31, 0, 0, 0);

  .action-btn {
    border-radius: 3px;
    @include padding(5, 16, 5, 16);
    @include margin(0, 20, 0, 0);
    cursor: pointer;
    @include fontSize(14);
    @include inter-semibold();
    color: $light-black;
  }

  .cancel {
    background: #e9e9e9;
    color:  $light-black;
  }

.save {
    background: #00aaf3;
    color:  $white;
  }

.save-next {
    background: #007cb1;
    color:  $white;
    margin-right: calculateRem(0);
  }

}