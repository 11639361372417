@import './../../styles/mixins.scss';
@import './../../styles/fonts.scss';
@import './../../styles/utils.scss';

.filter-item {
  @include flexbox();
  @include justify-content(center);
  @include flex-direction(column);
  max-width: max-content;

  .filter-name {
    @include inter-regular();
    @include fontSize(14);
    color: $light-black;
  }

  .filter-select {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .nav-link {
    min-width: calculateRem(160);
    @include hd {
      min-width: calculateRem(186);
    }
    @include fullHd {
      min-width: calculateRem(200);
    }
  }

  .drop-down {
    width: calculateRem(17);
    height: calculateRem(7);
  }

  .dropdown>a::after {
    content: none;
  }
}
