@import './../../styles/mixins.scss';
@import './../../styles/fonts.scss';

.versions-page {
  .page-content {
    margin-top: calculateRem(-15);
  }

  .page-header {
    @include flexbox();
    @include padding(10, 19.4, 14, 25);
    @include fontSize(14);
    @include inter-semibold();
    color: $light-black;
  }

  .versions-list-item {
    @include flexbox();
    @include align-items(center);
    @include justify-content(space-between);
    @include padding(14, 19.4, 14, 25);
    @include borderRadius(2);
    @include fontSize(15);
    @include inter-regular();
    color: $light-black;
    background-color: $gray-2;
    margin-bottom: calculateRem(8);

    &.current-published {
      @include height(80);
      background-color: $yellow-1;
    }

    &.previously-published {
      background-color: $yellow-2;
    }

    .version-name {
      max-width: 75%;
      word-break: break-word;
      @include fontSize(18);
      @include inter-medium();
      @extend .cursor-pointer;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }

  .first-col, .second-col, .third-col, .fourth-col {
    padding-right: calculateRem(12);
  }

  .first-col {
    @include flexbox();
    @include align-items(center);
    width: 50%;
  }

  .second-col, .third-col {
    width: 15%;
  }

  .fourth-col {
    width: 15%;
  }

  .actions-col {
    width: 5%;
    text-align: center;
    img {
      @include width(15);
      @include height(16);
      @extend .cursor-pointer;
    }
  }

}

.version-tooltip-content  {
  .tooltip-value {
      font-size: 10px;
      @include inter-medium();
  }
}
.version-tooltip {
  display: flex;
  justify-content: center;
}

.version-tooltip .tooltip-inner {
  width: fit-content;
  white-space: normal;
  max-width: 300px;
  text-align: start;
}