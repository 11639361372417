@import '../../../styles/mixins.scss';
@import '../../../styles/fonts.scss';
@import '../../../styles/variables.scss';

.filter-dropdown-container {
  .filter-list {
    min-width: 100px;

    .list-group {
      .row:last-child {
        margin-bottom: 0.5rem;
        .col {
          p {
            margin-bottom: 0;
          }
        }
      }
    }
  }
  .btn {
    background: none;
    padding: 0;
  }

  .ckeckbox, .radio {
    cursor: pointer;
  }

  btn-check:focus + .btn, .btn:focus {
    color: none;
    background-color: none;
    border-color: none;
    box-shadow: none
}

  .dropdown-menu {
    max-height: 400px;
    overflow-y: auto;
    padding-bottom: 0;
    padding-top: 0.75rem
  }

  p {
    @include inter-regular();
    @include fontSize(14);
    color: $light-black;
  }

  .icon-container {
    display: flex;
    flex-direction: row;
    height: 30px;

    .filter-icon {
      height: 20px;
      width: 20px;
    }

    .small-btn {
      width: 24px;
    }

    .mbn4 {
      margin-bottom: -4px;
    }
  }

  .action-container {
    position: sticky;
    bottom: 0;
    left: calc(50% - 4.5rem);
    @include flexbox();
    @include justify-content(center);
    @include margin(0, -12, 0, -12);
    @include padding(12);
    padding-top: 0.25rem;
    background-color: $white;
  }

  .action-btn {
    border-radius: 3px;
    @include padding(5, 16, 5, 16);
    cursor: pointer;
    @include fontSize(14);
    @include inter-semibold();
    color: $light-black;
    background: #00aaf3;
    color:  $white;
    @include flexbox();
    @include width(135);
    justify-content: center;
  }

  .selected-text {
    @include fontSize(10);
    color: $light-black;
    @include inter-regular();
    text-overflow: ellipsis;
    max-width: 4.5rem;
    overflow: hidden;
    white-space: nowrap;
  }

  .more-text {
    @include fontSize(8);
    color: $light-black;
    @include inter-regular();
    text-decoration: underline;
    color: $main-blue;
    @extend .cursor-pointer;
  }
}

.stream-filter{
  .dropdown-menu {
    width: max-content
  }  
  .col-2{
    width: 5%;
  }
  .col{
    white-space: nowrap;
  }
}