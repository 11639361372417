@import './../../../styles/mixins.scss';

.modal-body {
  color: $light-black;
  .first-row {
    @include flexbox();
    @include align-items(center);
    margin-bottom: calculateRem(20);
  }

  .input-fields {
    @include relative();
    .salary-input {
      @include width(200);
    }
    .input-error {
      color: $error-red;
      @include fontSize(10);
      @include absolute(auto, auto, -18px, 0px);
    }
  }
}

.modal-content {
  .actions.suggested-salary-actions {
    .clear-btn {
      margin-right: calculateRem(20);
    }
  
    .add-btn {
      @include width(135);
      @include margin(0, 15, 0, 0);
    }
  }
}