@import '../../../../styles/fonts.scss';
@import '../../../../styles/variables.scss';
@import '../../../../styles/mixins.scss';

.delete-conatiner {
    .message-container {
        text-align: center;
        @include padding(0, 0, 8, 0);
        .message-txt {
            @include fontSize(13);
            @include inter-regular();
            @include padding(0, 0, 10, 0);
        }
        .delete-txt {
            color: $delete-red;
            @include inter-semibold();
            @include fontSize(14);
            @include padding(0, 0, 10, 0);
        }
        .input-text {
            @include padding(8);
            @include fontSize(13);
            @include inter-regular();
            color: $light-black;
            border-radius: 2px;
            border: solid 1px rgba(47, 47, 47, 0.57);
            width: calculateRem(129);
        }

        .input-text::placeholder {
           text-align: center;
        }
    }

    .modal-footer .btn {
        margin-right: 0px;
    }

    .delete-container {
        justify-content: center;
        .delete-btn .cancel-btn {
            @include height(32);
            @include width(80);
            @include borderRadius(3);
            @include fontSize(14);
            @include inter-semibold();
        }
        .delete-btn {
            background: $delete-red;
            @include margin(0, 0, 0, 7);
        }
        .cancel-btn {
            background: $light-gray;
            color: $light-black;
            @include margin(0, 7, 0, 0);
        }
    }
}