@import './../../styles/mixins.scss';
@import './../../styles/fonts.scss';

.side-nav {
  background-color: $title-blue;
  height: calc(100vh - 5rem);
  position: fixed;

  .nav-expanded, .nav-collapsed {
    @include flexbox();
    @include flex-direction(column);
    @include justify-content(space-between);
    @include width(260);
    @include padding(31, 0, 0, 7);
    height: 100%;
    transition: width 0.3s;

    ul {
      @include padding(0);
    }

    .side-nav-list-item {
      display: block;
      color: $white;
      text-decoration: none;
      height: calculateRem(49);
      @include inter-regular();
      @include fontSize(16);
      @include padding(10, 0, 9, 0);
      @include margin(0, 0, 19, 0);
      border-top-left-radius: calculateRem(3);
      border-bottom-left-radius: calculateRem(3);
      white-space: nowrap;
      overflow: hidden;

      img {
        @include margin(0, 25, 0, 10);
        opacity: 0.73;
      }

      &.active {
        background-color: $main-blue;
        @include inter-bold();

        img {
          opacity: 1;
        }
      }

      &:hover:not(.active) {
        background-color: $main-blue;
      }
    }
  }

  .nav-collapsed {
    @include width(64);

    .side-nav-list-item{
      &.active {
        margin-right: calculateRem(-3);
        border-top-right-radius: calculateRem(3);
        border-bottom-right-radius: calculateRem(3);
      }
    }
  }
}