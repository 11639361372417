@import './../../../styles/mixins.scss';
@import './../../../styles/fonts.scss';
@import './../../../styles/utils.scss';

.stream-config {
  .heading-text {
    display: flex;
    flex-direction: row;
    align-items: baseline;
  }

  .country-text {
    margin-left: calculateRem(10);
  }

  .add-offset-form {
    @include justify-content(flex-start);

    .add-dropdown {
      width: 17rem;

      .dropdown-placeholder {
        width: 15.5875rem;
      }
    }

    .actions {
      margin-left: 4rem;
    }
  }

  .stream-list {
    margin-top: calculateRem(36);

    tbody tr td {
      &:nth-child(1) {
          width: 45%;
      }

      &:nth-child(2) {
        width: 48%;
      }

      &:nth-child(3) {
        width: 7%;
        text-align: end;
      }
    }
  }

  .rating-input-div {
    .rating-input {
      margin-left: calculateRem(20);
      width: 4.75rem;
    }
    .input-error {
      @include fontSize(10);
      left: 1.25rem;
    }
  }

  @include hdPlus {
    .rating-input-div {
      .input-error {
        left: 1.25rem;
        white-space: nowrap;
      }
    }

    .add-offset-form {
      .actions {
        .clear-btn {
          margin-left: 1rem;
        }
      }
    }
  }
  @include fullHd {
    .stream-list {  
      tbody tr td {
        &:nth-child(1) {
            width: 36%;
        }

        &:nth-child(2) {
          width: 57%;
        }

        &:nth-child(3) {
          width: 7%;
          text-align: end;
        }
      }
    }
  }

  .input-error {
    top: calculateRem(30);
    @include fontSize(8);
  }
}