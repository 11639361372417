@import './../../../../styles/mixins.scss';
@import './../../../../styles/variables.scss';

.modal-body {
    padding-top: 1rem !important;
}

.edit-rating-section,
.grade-section {
    .modal-column {
        width: 17%;

        &:first-child {
            width: 23%;
        }

        .modal-column-header {
            height: 20px;
            padding: 0 10px;
        }

        &>* {
            height: 60px;
            padding: 10px;
            overflow: hidden;
            @include flexbox();
            @include align-items(center);
            position: relative;
        }

        input {
            max-width: 100%;
        }

        .input-error {
            color: $error-red;
            @include fontSize(10);
            position: absolute;
            bottom: -2px;
            left: 10px;
        }

        > div:nth-child(2) {
            padding-top: 2px;
            height: 52px;
        }
    }
}

.grade-section {
    margin-bottom: 15px;

    .modal-column input {
        max-width: 80%;
    }
}