@import "./breakpoints";
// mixins for responsive styling

@mixin mobile {
  @media #{$mobile} {
    @content;
  }
}

@mixin desktopOnly {
  @media #{$desktop-only} {
    @content;
  }
}

@mixin hd {
  @media #{$hd} {
    @content;
  }
}

@mixin hdAbove {
  @media #{$hd-above} {
    @content;
  }
}

@mixin desktop {
  @media #{$desktop} {
    @content;
  }
}

@mixin tablet {
  @media #{$tablet} {
    @content;
  }
}

@mixin hdPlus {
  @media #{$hd-plus} {
    @content;
  }
}
@mixin fullHd {
  @media #{$full-hd} {
    @content;
  }
}

@mixin quadHd {
  @media #{$quad-hd} {
    @content;
  }
}
@mixin uHd {
  @media #{$u-hd} {
    @content;
  }
}
@mixin mobileLandscape {
  @media #{$mobile-landscape} {
    @content;
  }
}
@mixin tabletOnly {
  @media #{$tablet-only} {
    @content;
  }
}
