@import "./../../styles/mixins.scss";
@import "./../../styles/fonts.scss";

.pagination {
  @include inter-regular();
  @include fontSize(14);
  color: rgba(0, 0, 0, 0.9);
  opacity: 0.6;
  @include justify-content(flex-end);
  @include margin(10, 0, 10, 0)
}
