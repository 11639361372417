@import './../../styles/fonts.scss';
@import './../../styles/mixins.scss';
@import './../../styles/variables.scss';

.header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  color: $black;
  background-color: $white;
  box-shadow: 0 0 11px 0 rgba(0, 0, 0, 0.28);
  @include height(80);
  @include inter-medium();
  @include fontSize(14);
  @include flexbox();
  @include justify-content(space-between);
  @include padding(0, 46, 0, 24);
  z-index: 99989;

  .left-section {
    @include flexbox();
    @include align-items(center);

    .menu-icon {
      @extend .cursor-pointer;

      img {
        @include width(30);
        @include height(20);
      }
    }

    .app-heading-small {
      color: $light-black;
      @include fontSize(30);
      @include mulish-semibold();
      @include margin(0, 22, 4, 16);
    }

    .version-div {
      @include flexbox();
      @include align-items(center);

      .version-name {
        @include museo-sans-rounded-regular();
        @include fontSize(16);
        letter-spacing: 0.04px;
        color: $black1;
        white-space: nowrap;
      }

      .clone-icon {
        @extend .cursor-pointer;
        margin-left: calculateRem(7);

        img {
          @include width(12);
          @include height(14);
        }

      }
    }

    .info-text {
      margin-left: calculateRem(16);
      color: $dark-gray;
      @include inter-regular();
      @include fontSize(11);
    }

    .refresh-icon {
      margin-left: calculateRem(8);
      @extend .cursor-pointer;
    }
  }

  .right-section {
    @include inter-medium();
    @include fontSize(14);
    @include flexbox();

    .dropdown-toggle {
      @include padding(6, 8);
      @include flexbox();
      @include align-items(center);
      border: none;
      background-color: transparent;

      &::after {
        border-top: 0.43em solid;
        border-right: 0.4em solid transparent;
        border-left: 0.4em solid transparent;
        color: #2e2e2e;
      }
    }
    .dropdown-menu.show {
      left: initial;
      right: 0.5rem;
    }

    .dropdown-menu {
      @include padding(0);
      @include fontSize(14);
      
      .dropdown-item {
        @include padding(12, 16);

        &:active {
          background-color: transparent;
          color: black;
        }
        &:hover {
          background-color: transparent;
        }
      }
    }

    .username {
      color: $black;
      @include inter-regular();
      @include fontSize(14);
      margin-right: calculateRem(10);
    }

    .user-avatar {
      @include margin(0, 10, 0, 0);
      @include height(30);
      @include width(30);
      @include borderRadius(15);
    }

  }

  .nav-link {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .dropdown-menu {
    max-height: 400px;
    overflow-y: auto;
  }
}