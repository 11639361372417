@import './../../styles/mixins.scss';
@import './../../styles/fonts.scss';
@import './../../styles/variables.scss';

.page-title-container {
    @include margin(0, 0, 31, 0);

  .heading-text {
    display: flex;
    flex-direction: row;
    align-items: baseline;
  }

  .country-text {
    margin-left: calculateRem(23);
    @include inter-regular();
    @include fontSize(13);
    color: $light-black;
    opacity: 0.65;
  }

  a {
    color: $btn-primary-bg-color;
    @include fontSize(13);
  }

  .breadcrumb {
      align-items: flex-end;
  }

  .breadcrumb-item.active {
    color: $active-text;
    @include fontSize(13);
  }

  .breadcrumb-item + .breadcrumb-item::before {
    padding-right: 0;
    color: $breadcrumb-seperator-color;
  }

  .breadcrumb-item + .breadcrumb-item {
    padding-left: 0;
  }

}