@import "./../../styles/mixins.scss";

.layout-page {
  margin-top: calculateRem(80);
  @include flexbox();

  .content-half, .content-full {
    transition: width, margin 0.3s;
    @include padding(26, 51, 26, 30);
    width: calc(100vw - 16.25rem);
    margin-left: 16.25rem;
  }

  .content-full {
    width: calc(100vw - 4rem);
    margin-left: 4rem;
  }
}