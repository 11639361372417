@import '../../../styles/fonts.scss';

.qc-container {
    .col-12 .col-6 {
        &:first-child {
            padding-right: 25px;
        }

        &:last-child {
            padding-left: 25px;
        }

        .add-offset-form {
            margin: 0 0 1rem 0;

            .rating-input-div {
                .rating-input {
                    width: 4.75rem;
                }

                .input-error {
                    left: 0;
                    line-height: 20px;
                    margin-left: 0.25rem;
                    white-space: nowrap;
                }
            }

            .actions {
                .clear-btn {
                    margin-left: 1.5rem;
                    @media (min-width: 1000px) and (max-width: 1869px){
                        margin-left: 0.5rem;
                    }
                }
            }
        }

        .listing-table {
            tbody tr {
                td {
                    &:nth-child(1) {
                        width: 78%;
                    }

                    &:nth-child(2) {
                        width: 10%;
                    }

                    &:nth-child(3) {
                        text-align: end;
                        width: 12%;
                    }
                }
            }
        }
    }
    .form-item {
        width: 250px;
        white-space: pre-wrap;
    }
}

.qc-container{
    .add-offset-form{
        @media (min-width: 1000px) and (max-width: 1869px){
            padding-left: 0.7rem;
            padding-right: 0.7rem;
        }
        .add-btn{
            @media (min-width: 1440px) and (max-width: 1869px){
                margin-left: 0.7rem;
            }
        }
    }
}