@import './../../styles/fonts.scss';
@import './../../styles/variables.scss';
@import './../../styles/mixins.scss';

.login-page {
  width: 100vw;
  height: 100vh;
  background-color: $main-blue;
  @include flexbox();
  @include justify-content(flex-end);

  .login-div {
    width: 50%;
    background-color: $white;
    @include flexbox();
    @include justify-content(center);
    @include flex-direction(column);
    @include padding(55);

    .app-heading {
      @include mulish-semibold();
      @include fontSize(60);
      letter-spacing: calculateRem(0.16);
      color: $light-black;
    }

    .login-text {
      @include open-sans-regular();
      @include fontSize(18);
      @include margin(5, 0, 25, 0);
      letter-spacing: calculateRem(-0.03);
      color: $black;
    }

    .google-btn {
      border: none;
      background-color: transparent;
      text-align: left;
      @include padding(0);
      @include margin(25, 0);
      @extend .cursor-pointer;

      img {
        @include width(260);
        @include height(65);
        max-width: 260px;
        max-height: 65px;
      }
    }
  }
}