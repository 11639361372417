@import './../../../../styles/mixins.scss';
@import './../../../../styles/variables.scss';

.qualification-modal-body {

    .first-row, .second-row {
        .col-name {
            width: 30%;
        }
    }

    padding-bottom: 15px;
    .rating-input-div {
        input {
            margin-left: 0;
        }

        .input-error {
            left: 0;
        }

        &::after {
            @include margin(2, 4, 0, 0);
            color: rgba(47, 47, 47, 0.6);
        }
    }
}