@import './../../../../styles/mixins.scss';

.modal-body {
  color: $light-black;
  .first-row, .second-row {
    @include flexbox();
    @include align-items(center);
    margin-bottom: calculateRem(20);

    .col-name {
      width: 36%;
    }
  }

  .second-row {
    margin-bottom: calculateRem(0);

    .input-fields {
      width: 17%;
      @include relative();

      &::after {
        content: "%";
        @include absolute(0.3rem, auto, auto, 3.75rem);
        color: rgba(47, 47, 47, 0.6);
      }

      .input-error {
        color: $error-red;
        @include fontSize(10);
        @include absolute(auto, auto, -18px, 0);
      }
    }

    .rating-input {
      margin-left: 0;
    }
    .duplicate-error {
      width: 100%;
      text-align: center;
      color: $error-red;
      @include fontSize(10);
      @include absolute(auto, auto, -20px, 0);
      white-space: nowrap;
    }
  }
}

.modal-content {
  .actions {
    .clear-btn {
      margin-right: calculateRem(20);
    }
  
    .add-btn {
      @include width(85);
      @include margin(0, 15, 0, 0);
    }
  }
}