@import '../../../styles/fonts.scss';
@import '../../../styles/mixins.scss';

.listing-table {
    color: $light-black;
    tbody tr {
        .edit-icon, .delete-icon{
            visibility: hidden;
        }
    }

    tbody tr:hover {
        .edit-icon, .delete-icon {
            visibility: visible;
            @extend .cursor-pointer;
            @include width(22);
            @include height(22);
        }

        .delete-icon {
            margin-left: calculateRem(17);
        }
    }

    .header-text {
        @include fontSize(13);
        @include inter-semibold();
    }

    tbody tr {
        td {
            @include fontSize(14);
            @include padding(10, 0, 0, 0);

            &:first-child {
                @include fontSize(16);
                @include inter-semibold();
            }
        }
    }

    thead tr th {
        &:nth-child(1) {
            width: 15%;
        }

        &:nth-child(2) {
            width: 16%;
        }

        &:nth-child(3) {
            width: 16%;
        }

        &:nth-child(4) {
            width: 16%;
        }

        &:nth-child(5) {
            width: 16%;
        }

        &:nth-child(6) {
            width: 16%;
        }

        &:nth-child(8) {
            width: 5%;
        }
    }
}

.tooltip-content  {
    font-size: 12px;
    padding: 5px 0;

    .tooltip-heading {
        font-size: 9px;
        opacity: 0.69;
        line-height: normal;
    }

    .tooltip-value {
        font-size: 12px;
        @include inter-semibold();
    }
}