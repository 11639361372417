@import '../../../styles/fonts.scss';
@import '../../../styles/variables.scss';
@import '../../../styles/mixins.scss';

.config-container {
    color: $light-black;
    .heading {
        @include margin (0, 0, 21, 0);
        @include inter-medium;
        @include fontSize(18)
    }
    .config-list {
        @include margin (17, 0, 0, 0);
        @include flexbox;
        @include flex-wrap(wrap)
    }
    .title {
        @include flexbox;
        flex-direction: row;
        justify-content: space-between;
    }
    .delete-btn {
        @include padding(0, 18, 0, 18);
        height: calculateRem(27);
        border-radius: calculateRem(3);
        background: $delete-red;
        color: $white;
        @include inter-semibold();
        @include fontSize(14);
        @include flexbox;
        align-items: center;
        cursor: pointer;
    }
}