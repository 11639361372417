@import './../../styles/mixins.scss';
@import './../../styles/fonts.scss';
@import './../../styles/variables.scss';

.filter-item {
  @include flexbox();
  @include justify-content(center);
  @include flex-direction(column);
  max-width: max-content;

  .filter-name {
    @include inter-regular();
    @include fontSize(14);
    color: $light-black;
  }

  .filter-select {
    display: "inline-block";
    @include inter-semibold();
    @include fontSize(14);
    color: $light-black;
  }

  .drop-down {
    width: calculateRem(17);
    height: calculateRem(7);
    margin-left: calculateRem(10);
  }

  .dropdown-item {
    @include inter-regular();
    @include fontSize(14);
    color: $light-black;
  }

  .dropdown-menu {
    max-height: 400px;
    overflow-y: auto;
  }
  .stream-value{
    overflow:hidden;
    text-overflow: ellipsis;
    width: 8em;
  }
}
