//color variables
$main-blue: #00aaf3;
$title-blue: #256f9c;
$dark-blue: #007cb1;
$white: #fff;
$black: #000;
$red: red;
$red-1: #fc2d2d;
$black1: #2b2828;
$light-black: #2f2f2f;
$table-border: rgba(47, 47, 47, 0.2);
$dropdown-border: rgba(47, 47, 47, 0.5);
$light-gray: #e9e9e9;
$gray-1: #e9ecef;
$gray-2: #f0f7fa;
$dark-gray: #7f7f7f;
$active-text: #949292;
$green: #32841d;
$light-green: #8abe7c;
$yellow-1: #ffe2aa;
$yellow-2: #fff2da;

$input-border-color: rgba(47, 47, 47, 0.57);
$input-disabled-bg-color: #e2e2e2;

$btn-primary-bg-color: #00aaf3;
$btn-secondary-bg-color: #e9e9e9;

$error-red: #ff0000;
$delete-red: #ff4747;

$breadcrumb-seperator-color: #00aaf3;

// scss-docs-start cursor-variables-map
$cursor-variables: pointer,
crosshair,
not-allowed,
grabbing,
grab,
zoom-in,
zoom-out !default;