@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&family=Mulish:wght@600&family=Open+Sans&display=swap');

@mixin inter-light() {
  font-family: 'Inter', sans-serif;
  font-weight: 300;
}
@mixin inter-regular() {
  font-family: 'Inter', sans-serif;
  font-weight: 400;
}
@mixin inter-medium() {
  font-family: 'Inter', sans-serif;
  font-weight: 500;
}
@mixin inter-semibold() {
  font-family: 'Inter', sans-serif;
  font-weight: 600;
}
@mixin inter-bold() {
  font-family: 'Inter', sans-serif;
  font-weight: 700;
}
@mixin inter() {
  font-family: 'Inter', sans-serif;
}
@mixin mulish-semibold() {
  font-family: 'Mulish', sans-serif;
  font-weight: 600;
}
@mixin open-sans-regular() {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
}

@font-face {
  font-family: "Museo Sans Rounded 500";
  src: url('../fonts/MuseoSansRounded-500.otf');
  font-weight: normal;
}

@mixin museo-sans-rounded-regular() {
  font-family: "Museo Sans Rounded 500";
  font-weight: normal;
}