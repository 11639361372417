@import './../../styles/mixins.scss';

.suggest-salary {
  .filters-div {
    @include margin(38, 0, 43, 0);
  }
  .heading-text {
    display: flex;
    flex-direction: row;
    align-items: baseline;
  }

  .country-text {
    margin-left: calculateRem(10);
  }
}