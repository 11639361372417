@import './../../styles/mixins.scss';
@import './../../styles/fonts.scss';

.config-rating-filter {
  min-width: 7.0625rem;
  @include flexbox();
  @include justify-content(center);
  @include flex-direction(column);
  max-width: max-content;
  border-radius: 2px;
  border: solid 1px $dropdown-border;

  .filter-name {
    @include inter-regular();
    @include fontSize(14);
    color: $light-black;
  }

  .filter-select {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .drop-down {
    width: calculateRem(17);
    height: calculateRem(7);
  }

  .nav-link {
    @include padding(2, 8, 2, 8);
    @include inter-regular();
    @include fontSize(13);
    color: $light-black;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  :focus-visible {
    outline: none;
  }

  .dropdown-toggle::after {
    position: absolute;
    right: 4px;
    top: 40%;
  }
}
